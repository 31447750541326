@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('swiper/swiper-bundle.min.css');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
section {
  margin: 0;
}
h1 {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 6.2rem;
  line-height: 120%;
  color: #0f204d;
  margin: 0;
}
h2 {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 4.6rem;
  line-height: 130%;
  color: #0f204d;
  margin: 0;
}
h3,
h4 {
  margin: 0;
}
.container {
  width: 100%;
  max-width: 1235px;
  padding: 0 10px;
  margin: 0 auto;
}
.hs-error-msgs {
  margin: 0;
  padding: 0;
}
.hs-error-msgs li {
  list-style: none;
}
.hs-error-msg {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 130%;
  color: #fe4059;
}
.actions input[type='submit'] {
  height: 55px;
  width: 100%;
  max-width: unset;
  margin-top: 5.1rem;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 130%;
  color: #ffffff;
  background: #fe4059;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
.btn-default {
  background: #fe4059;
  border-radius: 6px;
  width: 280px;
  height: 72px;

  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.3rem;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: all 0.2s ease-in-out;
}
.btn-default:hover,
.actions input[type='submit']:hover {
  background: #f12d47;
}
.btn-default img {
  margin-right: 12px;
}
.up-title {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 150%;
  letter-spacing: 0.1em;
  color: #6690f8;
}
.group-input,
.hs-form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;
  position: relative;
}
.group-input .default-label,
.hs-form-field > label {
  margin-bottom: 0.95rem;
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 124.5%;
  color: #0f204d;
  transition: all 0.2s ease-in-out;
}
.group-input .default-input,
.hs-input {
  width: 100%;
  background: #fff;
  border: 1.32941px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 7.0902px;
  height: 52.98px;
  padding-left: 23px;
  transition: all 0.2s ease-in-out;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 115%;
  color: #0f204d;
  outline: none;
}
.group-input .default-input:hover,
.hs-input:hover {
  border: 1px solid #606f8e;
  color: #0f204d;
}
.group-input .default-input:focus,
.hs-input:focus {
  border: 1px solid #6690f8;
  color: #0f204d;
}
.group-input:focus-within .default-label {
  color: #6690f8;
}
.group-input:focus-within .default-label,
.hs-form-field:focus-within label {
  color: #6690f8;
}
input:focus-visible {
  outline: none;
}
.MuiListItem-button {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1.6rem !important;
  line-height: 150% !important;
  color: #0f204d !important;
}
.group-input .default-input::placeholder {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 124.5%;
  color: #606f8e;
}
.group-input.has-error .default-input {
  border: 1px solid #fe4059;
}
.group-input.has-icon .default-input {
  padding-right: 45px;
}
.group-input.has-icon img {
  position: absolute;
  right: 19px;
  top: 44px;
}
.group-input.has-error .default-label {
  color: #fe4059;
}
.group-input.has-error small {
  margin: 0.8rem 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 130%;
  color: #fe4059;
}
.group-input.ok .default-input {
  border: 1px solid #25b779;
}
.group-input.ok .default-label {
  color: #25b779;
}
.group-input.ok small {
  margin: 0.8rem 0;
  color: #25b779;
}

@media screen and (max-width: 1080px) {
  html {
    font-size: 55%;
  }
}
@media screen and (max-width: 1004px) {
  html {
    font-size: 62.5%;
  }
  .container {
    padding: 0 16px;
  }
}
@media screen and (max-width: 600px) {
  h2 {
    font-size: 3rem;
  }
  .up-title {
    font-size: 1.2rem;
  }
}

.MuiSlider-root {
  padding: 14px 0 !important;
}
.rive {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
